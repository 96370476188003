import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tabbable from 'tabbable';

import { MenuDownIcon } from '@mc/wink-icons';
import cx from 'classnames';
import chainHandlers from '@mc/fn/chainHandlers';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import useId from '@mc/hooks/useId';

import Animate from '../Animate';
import Button from '../Button';
import IconButton from '../IconButton';
import Popup from '../Popup';
import TextButton from '../TextButton';

import ActionListItem from './ActionListItem';
import stylesheet from './ActionList.less';

const ActionListMenu = React.forwardRef(function ActionListMenu(
  { children, setIsExpanded, isExpanded, buttonRef, ...props },
  menuRef,
) {
  const handleClick = () => {
    /** Close menu when list item is clicked */
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  const handleKeyDown = (e) => {
    const { target, key, shiftKey } = e;
    const tabbables = tabbable(menuRef.current);
    const firstFocusableEl = tabbables[0];
    const lastFocusableEl = tabbables[tabbables.length - 1];

    switch (key) {
      case ' ': {
        if (target.tagName === 'A' || target.tagName === 'BUTTON') {
          /** This will prevent scrolling on the page when a link is focused. */
          e.preventDefault();
        }
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();
        /** Focus to first element after it reaches last item */
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
        } else {
          const index = tabbables.indexOf(document.activeElement);
          tabbables[index + 1].focus();
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        /** Focus to last element after it reaches first item */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
        } else {
          const index = tabbables.indexOf(document.activeElement);
          if (index === -1) {
            // In case focus gets lost or user clicks a disabled item
            lastFocusableEl.focus();
          } else {
            tabbables[index - 1].focus();
          }
        }
        break;
      }
      case 'Tab': {
        if (!shiftKey) {
          /** When tabbing, focus to first element after it reaches last item */
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          /** Focus to last element after it reaches first item when pressing shift+key*/
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        }
        break;
      }

      case 'Escape': {
        /** Close popover on ESC*/
        e.preventDefault();
        setIsExpanded(false);
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      className={stylesheet.menu}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      ref={menuRef}
      {...props}
    >
      {children}
    </div>
  );
});

ActionListMenu.propTypes = {
  /** Trigger reference */
  buttonRef: PropTypes.object,
  /** Consumes `ActionListItem` components */
  children: PropTypes.node,
  /** Current state on whether action list is expanded or collapsed */
  isExpanded: PropTypes.bool,
  /** Function that updates isExpanded value */
  setIsExpanded: PropTypes.func,
};

/** An Action List is a button that can have multiple actions taken from a pop-over menu on click.
 * These actions may be buttons or links. This should be able to be styled multiple-ways, not just as the
 * secondary button style. */
const ActionList = React.forwardRef(function ActionList(
  {
    children,
    label,
    icon,
    onBlur,
    type = 'secondary',
    onOpen = () => {},
    placement = 'bottom-start',
    ...props
  },
  forwardedRef,
) {
  const buttonId = useId();
  const buttonRef = useRef();
  const menuRef = useRef();
  const lastCalledRef = useRef(false);

  const shouldSkipBlur = useRef();
  const isMounted = useRef(false);
  const ref = useJoinedRef(forwardedRef, buttonRef);
  const [isExpanded, setIsExpanded] = useState(false);

  /** Set Trigger component */
  let Trigger = Button;

  if (type === 'inline') {
    Trigger = TextButton;
  }

  if (icon) {
    Trigger = IconButton;
  }

  const handleClick = () => {
    setIsExpanded((prevValue) => !prevValue);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    /** Focus first list item when menu is opened */
    if (isExpanded) {
      const trigger = buttonRef.current;
      const tabbables = tabbable(menuRef.current);
      if (tabbables.length) {
        tabbables[0].focus();
      }

      return () => {
        if (isMounted.current) {
          trigger.focus();
        }
      };
    }
  }, [isExpanded]);

  useEffect(() => {
    if (!lastCalledRef.current && isExpanded) {
      onOpen();
      lastCalledRef.current = true;
    }
    if (!isExpanded) {
      lastCalledRef.current = false;
    }
  }, [isExpanded, onOpen]);

  return (
    <div
      className={stylesheet.root}
      /** Set skip blur value when focused with keypress */
      onBlur={chainHandlers(onBlur, () => {
        shouldSkipBlur.current = false;
        requestAnimationFrame(() => {
          /** Skip blur if trigger is focused with keypress */
          if (shouldSkipBlur.current) {
            shouldSkipBlur.current = false;
          } else if (isMounted.current) {
            setIsExpanded(false);
          }
        });
      })}
      onFocus={() => {
        shouldSkipBlur.current = true;
      }}
    >
      <Trigger
        id={buttonId}
        onClick={handleClick}
        /**Don't apply the style to IconButton */
        className={cx({
          [stylesheet.triggerIconWithLabel]: !icon,
        })}
        aria-expanded={isExpanded}
        type={type === 'inline' ? undefined : type}
        icon={icon ? icon : undefined}
        label={icon ? label : undefined}
        ref={ref}
        aria-haspopup="true"
        {...props}
      >
        <React.Fragment>
          {label}
          <MenuDownIcon className={stylesheet.icon} />
        </React.Fragment>
      </Trigger>

      <Animate
        component={Popup}
        toggle={isExpanded}
        className={stylesheet.popup}
        offset={8}
        placement={placement}
        targetRef={buttonRef}
      >
        <ActionListMenu
          aria-labelledby={buttonId}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          buttonRef={buttonRef}
          ref={menuRef}
        >
          {children}
        </ActionListMenu>
      </Animate>
    </div>
  );
});

ActionList.propTypes = {
  /** Consumes `ActionMenu` and `ActionItem` components */
  children: PropTypes.node.isRequired,
  /** Type of icon for IconButton component */
  icon: PropTypes.node,
  /** Toggle content for Button and label attribute for IconButton */
  label: PropTypes.node.isRequired,
  /** Triggers when the input is blurred. */
  onBlur: PropTypes.func,
  /** Triggers when the menu is opened. */
  onOpen: PropTypes.func,
  /** The default placement of the menu, passed along to Popup  */
  placement: PropTypes.oneOf(['bottom', 'bottom-start', 'bottom-end']),
  /** The different variants of a Button */
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'inline']),
};

export { ActionList as default, ActionListItem };

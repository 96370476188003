import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './Modal.less';

const ModalContent = ({ children, className, ...rest }) => {
  return (
    <div
      className={cx(stylesheet.content, className)}
      style={{ flexGrow: 2 }}
      {...rest}
    >
      {children}
    </div>
  );
};

ModalContent.propTypes = {
  /** The content content inside the modal.  */
  children: PropTypes.node,
};

export default ModalContent;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './ClusterLayout.less';

const ALIGN_ITEMS_MAP = {
  'flex-start': stylesheet.alignItemsFlexStart,
  'flex-end': stylesheet.alignItemsFlexEnd,
  center: stylesheet.alignItemsCenter,
  baseline: stylesheet.alignItemsBaseline,
  stretch: stylesheet.alignItemsStretch,
};

const JUSTIFY_CONTENT_MAP = {
  'flex-start': stylesheet.justifyFlexStart,
  'flex-end': stylesheet.justifyFlexEnd,
  center: stylesheet.justifyCenter,
  'space-around': stylesheet.justifySpaceAround,
  'space-between': stylesheet.justifySpaceBetween,
};

/**
 * ClusterLayout's children are laid out along a horizontal axis. If the
 * viewport shrinks such that this layout can no longer accommodate its children
 * on the horizontal axis, then the layout wraps until it can, creating more
 * horizontal axes as it goes. The horizontal axes are the "main" axes.
 *
 * `ClusterLayout` vertically centers children with respect to the main axes by
 * default, but you can override this behavior by passing an `alignItems` prop.
 *
 * Similarly, you can determine how `ClusterLayout` horizontally lays out its
 * children by passing a `justifyContent` prop.
 */
const ClusterLayout = React.forwardRef(function ClusterLayout(
  {
    alignItems = 'center',
    as: Component = 'div',
    children,
    className,
    justifyContent = 'flex-start',
    gap = 4,
    nowrap = false,
    ...props
  },
  forwardedRef,
) {
  return (
    <div
      className={cx(className, stylesheet.cluster, nowrap && stylesheet.nowrap)}
      ref={forwardedRef}
      {...props}
    >
      <Component
        className={cx(
          ALIGN_ITEMS_MAP[alignItems],
          JUSTIFY_CONTENT_MAP[justifyContent],
          gap && stylesheet[`spacing${gap}`],
        )}
      >
        {children}
      </Component>
    </div>
  );
});

ClusterLayout.propTypes = {
  /** Vertical alignment with respect to the main axes */
  alignItems: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch',
  ]),
  /** The node used for the innermost element of ClusterLayout. */
  as: PropTypes.any,
  children: PropTypes.node,
  /** Optional classes set on the root element of ClusterLayout. */
  className: PropTypes.string,
  /** Controls spacing between children. Uses component spacing. */
  gap: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  /** Horizontal alignment along the main axis. */
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
  ]),
  /** If true, the layout uses exactly one main axis. */
  nowrap: PropTypes.bool,
};

export default ClusterLayout;

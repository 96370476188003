import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '../Text';
import { useAnnouncer } from '../LiveRegion';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import stylesheet from './Loading.less';

const Loading = function Loading({
  children,
  className,
  type = 'small',
  infinite = true,
}) {
  // Translation for default loading copy text
  const loadingCopy = useDsTranslateMessage({
    id: 'mcds.loading.copy',
    defaultMessage: 'Loading',
  });
  // Screen reader to alert loading indicator
  const announce = useAnnouncer();
  useEffect(() => {
    if (children) {
      announce(children);
    } else {
      announce(loadingCopy);
    }
  }, [announce, children, loadingCopy]);

  return (
    <div
      className={cx(stylesheet.root, stylesheet[type], className)}
      aria-busy="true"
    >
      <div
        className={
          infinite
            ? cx(stylesheet.indicatorSizing, stylesheet.indicatorInfinite)
            : cx(stylesheet.indicatorSizing, stylesheet.indicatorLinear)
        }
      ></div>
      {children ? (
        <Text appearance="small">{children}</Text>
      ) : (
        // If no copy exists, this will be announced to screen readers while navigating
        <p className="wink-visually-hidden">{loadingCopy}</p>
      )}
    </div>
  );
};

Loading.propTypes = {
  /** Loading copy */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  /** Whether or not the indicator infinitely cycles */
  infinite: PropTypes.bool,
  /** The loading indicator has two types. The small loading indicator renders inline and the large loading indicator is stacked. */
  type: PropTypes.oneOf(['small', 'large']),
};

export default Loading;

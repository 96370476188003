import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './StackLayout.less';

/**
 * `StackLayout` stacks **block-level** elements.
 *
 * All children are stacked in a single column. Inline elements get stretched
 * and become blocks. If you wish to avoid this, wrap inline elements with
 * another element, like a `<div>`.
 */
const StackLayout = React.forwardRef(function StackLayout(
  { as: Component = 'div', children, className, gap = 4, ...props },
  forwardedRef,
) {
  // Implements the `StackLayout` on every-layout.dev:
  // https://every-layout.dev/layouts/stack
  //
  // Note that there is a bug in every-layout's version, which uses CSS
  // variables: Nested `StackLayout`s override the CSS variable of its parent,
  // causing unwanted `gap` values.
  return (
    <Component
      className={cx(
        stylesheet.stack,
        gap && stylesheet[`spacing${gap}`],
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Component>
  );
});

StackLayout.propTypes = {
  /** The node used for the root element of StackLayout. */
  as: PropTypes.any,
  children: PropTypes.node,
  /** Optional classes set on the root element of StackLayout. */
  className: PropTypes.string,
  /** Controls spacing between children. Uses component spacing. */
  gap: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
};

export default StackLayout;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ArrowLeftIcon } from '@mc/wink-icons';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import IconButton from '../IconButton';
import StackLayout from '../StackLayout';
import { WizardBreadcrumbs } from '../Wizard';
import ContentArea from '../ContentArea';
import { TranslatePage } from '../Page/TranslatePage';
import stylesheet from './PageHeader.less';

/**
 * `PageHeader` should always be wrapped by `Page` to create an isolated stacking context which does not interfere with other z-indexes.
 */
const PageHeader = React.forwardRef(function PageHeader(
  {
    children,
    title,
    actions,
    referrer,
    referrerIcon = null,
    onReferrerClick = null,
    breadcrumbs = null,
    focusMode = false,
    size = 'standard',
    className,
    isTitleTruncated = false,
    ...props
  },
  forwardedRef,
) {
  // Translate default text
  const { backText } = TranslatePage();
  const isUsingWizardBreadcrumbs =
    breadcrumbs && breadcrumbs.type === WizardBreadcrumbs;
  return (
    <div {...props} className={stylesheet.container}>
      <ContentArea
        size={size}
        as="header"
        ref={forwardedRef}
        className={cx(
          stylesheet.header,
          focusMode && stylesheet.focusMode,
          className,
        )}
      >
        <ClusterLayout
          nowrap
          className={cx(
            isTitleTruncated && stylesheet.titleOverflowEllipsisCluster,
          )}
        >
          {referrer && (
            <div>
              <IconButton
                label={backText}
                onClick={onReferrerClick}
                href={referrer}
                icon={referrerIcon ?? <ArrowLeftIcon />}
              />
            </div>
          )}
          <StackLayout
            style={{
              flexDirection: isUsingWizardBreadcrumbs
                ? 'column-reverse'
                : 'column',
            }}
            gap={0}
            className={cx(
              isTitleTruncated && stylesheet.titleOverflowEllipsisStack,
            )}
          >
            {breadcrumbs}
            <Heading
              level={1}
              appearance={breadcrumbs ? 'heading-4' : 'heading-3'}
              className={cx(
                isTitleTruncated && stylesheet.titleOverflowEllipsisHeading,
              )}
            >
              {title}
            </Heading>
          </StackLayout>
        </ClusterLayout>

        <div>{children}</div>

        {actions && (
          <ClusterLayout className={stylesheet.actions}>
            {actions}
          </ClusterLayout>
        )}
      </ContentArea>
    </div>
  );
});

PageHeader.propTypes = {
  /** Should be an instance of <PageHeaderActions> */
  actions: PropTypes.node,
  /** An instance of WizardBreadcrumbs */
  breadcrumbs: PropTypes.node,
  /** Any custom content that will appear in the center of the bar. Please note that you will be responsible for handling any
   * mobile styles for these children.
   */
  children: PropTypes.node,
  /** Custom className for header ContentArea */
  className: PropTypes.string,
  /**
   * Enabling focus mode to true will set the left padding of the header to exactly 100px,
   *  which is the appropriate space needed for the Freddie logo provided by the app's left nav.
   * */
  focusMode: PropTypes.bool,
  /** Setting this to true will apply text-overflow: ellipsis to the title and prevent wrapping. */
  isTitleTruncated: PropTypes.bool,
  /** Function that runs when clicking the referrer link */
  onReferrerClick: PropTypes.func,
  /** *** Deprecated *** Please use breadcrumbs for page navigation. *** Deprecated ***  */
  referrer: PropTypes.string,
  /** Custom icon to be used for referrer back button. */
  referrerIcon: PropTypes.node,
  /** Most pages should use standard (which is the default). Use wide for full width sizes. Use narrow for a more compact page header. */
  size: PropTypes.oneOf(['standard', 'narrow', 'wide']),
  /** The main text of the Header. The string or node passed in here will be wrapped with a Heading component. */
  title: PropTypes.node.isRequired,
};

export default PageHeader;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import stylesheet from './Heading.less';

/**
 * Many screen reader users navigate by heading. Add headings for each section
 * of the page, even if they must be visually hidden — as a screen reader user,
 * I want to be able to navigate through each section easily.
 */
const Heading = React.forwardRef(function Heading(
  {
    children,
    className,
    level,
    appearance = `heading-${level}`,
    textWrap,
    ...props
  },
  forwardedRef,
) {
  const H = `h${level}`;
  return (
    <H
      className={cx(
        stylesheet[appearance],
        className,
        stylesheet.root,
        textWrap && stylesheet[`text-${textWrap}`],
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </H>
  );
});

Heading.propTypes = {
  /** Override the heading level's default appearance. */
  appearance: PropTypes.oneOf([
    'heading-1',
    'heading-2',
    'heading-3',
    'heading-4',
  ]),
  children: PropTypes.node,
  /** The semantic heading level */
  level: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
  textWrap: PropTypes.oneOf(['wrap', 'nowrap', 'balance', 'pretty']),
};

export default Heading;

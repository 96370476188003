import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ArrowRightIcon, CrossIcon } from '@mc/wink-icons';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import StackLayout from '../StackLayout';
import IconButton from '../IconButton';
import TextButton from '../TextButton';
import Portal from '../Portal';
import Text from '../Text';
import stylesheet from './AnnouncementCard.less';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

const PortalWrapper = ({ condition, children }) => {
  return condition ? children : <Portal prependNode>{children}</Portal>;
};

PortalWrapper.propTypes = {
  children: PropTypes.node,
  /** To set Portal wrapper or not */
  condition: PropTypes.bool,
};

const AnnouncementCard = React.forwardRef(function AnnouncementCard(
  {
    className,
    description,
    imgSrc,
    linkUrl,
    linkTarget,
    linkTitle,
    onClose,
    onLinkClick,
    title,
    tunnelId = 'portal-announcement',
    ...rest
  },
  forwardedRef,
) {
  const classList = cx(stylesheet.root, className);
  const { isPhone } = useBreakpoints();

  // Translate default text
  const dismissText = useDsTranslateMessage({
    id: 'mcds.announcement_card.dismiss_text',
    defaultMessage: 'Dismiss announcement card',
  });

  const mountNode = useCallback(() => {
    return document.getElementById(tunnelId);
  }, [tunnelId]);

  const announcement = (
    <StackLayout className={classList} ref={forwardedRef} {...rest}>
      {onClose && (
        <div className={stylesheet.closeIcon}>
          <IconButton
            label={dismissText}
            icon={<CrossIcon />}
            onClick={onClose}
          />
        </div>
      )}
      <StackLayout gap={6}>
        {!isPhone && imgSrc && (
          <div className={stylesheet.image}>
            <img src={imgSrc} alt={title} />
          </div>
        )}
        <div>
          {title && (
            <Text appearance={isPhone ? 'medium' : 'large'}>{title}</Text>
          )}
          {description && (
            <Text as="span" appearance="small-secondary">
              {description}
            </Text>
          )}
        </div>
        {linkTitle && linkUrl && (
          <TextButton
            href={linkUrl}
            onClick={onLinkClick ? onLinkClick : onClose}
            target={linkTarget}
          >
            {linkTitle} <ArrowRightIcon />
          </TextButton>
        )}
      </StackLayout>
    </StackLayout>
  );

  return <Portal mountNode={mountNode}>{announcement}</Portal>;
});

AnnouncementCard.propTypes = {
  /** A custom class name that is appended to the announcement card classes. */
  className: PropTypes.string,
  /** Copy that follows the title of the announcement card. */
  description: PropTypes.string.isRequired,
  /** Image to display at the top of the announcement card block. */
  imgSrc: PropTypes.string.isRequired,
  /** The link's 'target' attribute.  Use this if you want to open links in a new tab */
  linkTarget: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  /** Text to display for a link */
  linkTitle: PropTypes.string,
  /** URL of a link */
  linkUrl: PropTypes.string,
  /** Function to dismiss AnnouncementCard */
  onClose: PropTypes.func.isRequired,
  /** Function when link is clicked, optional most likely used for GA tracking of link vs. close click */
  onLinkClick: PropTypes.func,
  /** Bolded title */
  title: PropTypes.string.isRequired,
  /** Automatically set to append `AnnouncementCard` to the app's `AnnouncementCardTunnel` which is our live region. */
  tunnelId: PropTypes.string,
};

export default AnnouncementCard;

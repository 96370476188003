import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import stylesheet from './Page.less';

/**
 * Wrap this around the `PageHeader` and `PageContent` components. Page will
 * provide an isolated
 * [stacking context](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context)
 * for the `PageHeader` component inside it.
 */
const Page = React.forwardRef(function Page(
  { children, className },
  forwardedRef,
) {
  return (
    <div className={cx(stylesheet.root, className)} ref={forwardedRef}>
      {children}
    </div>
  );
});

Page.propTypes = {
  /** All your content should be added as children */
  children: PropTypes.node.isRequired,
  className: PropTypes.any,
};

export default Page;

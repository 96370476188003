import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuDownIcon } from '@mc/wink-icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import Listbox from '../Listbox';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './Select.less';

const InputListboxTrigger = React.forwardRef(
  (
    {
      filter,
      onFilterChange,
      onBlur,
      onToggle,
      id,
      selected = [],
      placeholder,
      renderSelectedValue,
      isExpanded,
      options,
      onHighlight,
      onSelect,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <input
        autoComplete="off"
        {...props}
        id={id}
        ref={forwardedRef}
        className={cx(stylesheet.trigger, stylesheet.inputTrigger)}
        placeholder={placeholder}
        value={
          filter === undefined ? renderSelectedValue(selected, '') : filter
        }
        onChange={(event) => {
          onFilterChange(event.target.value);
        }}
        onClick={() => {
          onToggle();
        }}
        onBlur={(event) => {
          onFilterChange(undefined);
          onBlur(event);
        }}
      />
    );
  },
);

InputListboxTrigger.propTypes = {
  filter: PropTypes.string,
  id: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  // Unused; used for emulating keyboard search for non-inputs
  onHighlight: PropTypes.func,
  onKeyDown: PropTypes.func.isRequired,
  // Unused; used for emulating keyboard search for non-inputs
  onSelect: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  // Unused; used for emulating keyboard search for non-inputs
  options: PropTypes.array,
  placeholder: PropTypes.node,
  renderSelectedValue: PropTypes.func,
  selected: PropTypes.array,
};

const SelectListboxTrigger = React.forwardRef(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      options,
      isExpanded,
      filter,
      onFilterChange,
      onBlur,
      onKeyDown,
      onHighlight,
      onSelect,
      onToggle,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <div
        tabIndex={0}
        className={stylesheet.trigger}
        {...props}
        ref={forwardedRef}
        onBlur={onBlur}
        onKeyDown={chainHandlers(onKeyDown, (event) => {
          emulateSelectKeyboardSearch(event, {
            options,
            isExpanded,
            onSelect,
            onHighlight,
            onToggle,
          });
        })}
        onClick={onToggle}
      >
        {renderSelectedValue(selected, placeholder)}
      </div>
    );
  },
);

SelectListboxTrigger.propTypes = {
  // Filters are only used with inputs.
  filter: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  // Filters are only used with inputs.
  onFilterChange: PropTypes.func,
  onHighlight: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.node,
  renderSelectedValue: PropTypes.func,
  selected: PropTypes.array,
};

const Select = React.forwardRef(function Select(
  {
    'aria-labelledby': ariaLabelledBy,
    className,
    children,
    mode = 'native',
    searchable = false,
    multiple = false,
    disabled = false,
    readOnly = false,
    hideLabel = false,
    helpText,
    error,
    label,
    onChange,
    miscText,
    renderSelectedValue,
    ...props
  },
  forwardedRef,
) {
  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();
  const describedBy = ariaDescribedByIds(
    (error || helpText) && helpTextId,
    miscText && miscTextId,
  );

  const isListbox = mode === 'listbox' || searchable || multiple;

  // We need to handle three cases:
  //
  // 1. Only pass a `label`. Native selects use a native label element, but
  //    Listbox isn't a native select so it must manually use `aria-labelledby`.
  // 2. Only pass an `aria-labelledby`. We don't render a label element.
  // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
  //    `aria-labelledby` attribute.
  const labelledBy = ariaLabelledByIds(
    ariaLabelledBy,
    (ariaLabelledBy || isListbox) && label && labelId,
  );

  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.error]: !!error,
      })}
    >
      <div className={stylesheet.before}>
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {miscText && (
          <span id={miscTextId} className={stylesheet.secondary}>
            {miscText}
          </span>
        )}
      </div>
      <div className={stylesheet.selectWrapper}>
        {isListbox ? (
          <Listbox
            matchTargetWidth
            multiple={multiple}
            trigger={searchable ? InputListboxTrigger : SelectListboxTrigger}
            renderSelectedValue={renderSelectedValue}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={onChange}
            ref={forwardedRef}
            {...props}
          >
            {children}
          </Listbox>
        ) : (
          <select
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={(event) => onChange(event.target.value)}
            ref={forwardedRef}
            {...props}
          >
            {children}
          </select>
        )}

        <div className={stylesheet.indicator}>
          <MenuDownIcon />
        </div>
      </div>
      {error ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.errorMessage)}
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          id={helpTextId}
          className={cx(stylesheet.after, stylesheet.secondary)}
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
});

Select.propTypes = {
  /** @ignore Throwaway propType to do complex checks. */
  // eslint-disable-next-line react/no-unused-prop-types
  _: (props, propName, componentName) => {
    if (!props.label && !props['aria-labelledby']) {
      return new Error(formatError(ERROR_MUST_PROVIDE_LABEL, componentName));
    }
  },
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /** Should be children of the `Option` component*/
  children: PropTypes.node.isRequired,
  /** Makes the input unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error: PropTypes.string,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the select. */
  label: PropTypes.node,
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText: PropTypes.node,
  /** Switches between the two modes:
   * Native: uses a native `<select>` including the native HTML options menu. This is the preferred mode for most Selects.
   * Listbox: Uses a custom implemenatation of an ARIA listbox. Useful for Selects that need complex options, such as
   * images or styling. Please avoid using listbox mode if your options are plain text.
   */
  mode: PropTypes.oneOf(['native', 'listbox']),
  /** Enables a multiselect. Two important notes abou this prop:
   * 1. multiselect will render "listbox" mode even if you have not chosen it in the mode prop (native multiple select is a subpar experience).
   * 2. The value will always be cast to an array onChange so ensure you intend to work with an array (the value can also be null).
   */
  multiple: PropTypes.bool,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** Triggers when the object select is focused. */
  onFocus: PropTypes.func,
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly: PropTypes.bool,
  /** Override the default display of the selected value in the collapsed select.
   * Defaults to the children of the selected option for single selects, multi selects show a count of selected values. */
  renderSelectedValue: PropTypes.func,
  /** When true, the trigger for the Select will be an input and the value will act as a filter for the options */
  searchable: PropTypes.bool,
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value: PropTypes.any,
};

export default Select;

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuDownIcon } from '@mc/wink-icons';
import useId from '@mc/hooks/useId';
import usePrevious from '@mc/hooks/usePrevious';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import useUpdateEffect from '@mc/hooks/useUpdateEffect';
import chainHandlers from '@mc/fn/chainHandlers';
import Popup from '../Popup';
import Input from '../Input';
import stylesheet from './Combobox.less';

const closestMatchIndex = (options, value) => {
  const lowercaseValue = value.toLowerCase();
  return options
    .map((option) => option.toLowerCase())
    .findIndex((option) => option.startsWith(lowercaseValue));
};

const Combobox = React.forwardRef(function Combobox(
  {
    'aria-multiselectable': ariaMultiselectable,
    children,
    label,
    onChange,
    value = '',
    onSelect = () => {},
    closeOnSelect = true,
    autohighlight = false,
    unsafe_key,
    autoFocus = false,
    ...props
  },
  forwardedRef,
) {
  const firstActiveDescendant = autohighlight ? 0 : -1;
  const [isExpanded, setIsExpanded] = useState(autoFocus);
  const [activeDescendant, setActiveDescendant] = useState(
    firstActiveDescendant,
  );
  const ref = useRef();
  const inputRef = useJoinedRef(forwardedRef, ref);
  const optionsRef = useRef([]);
  const id = useId();
  const labelId = `${id}-label`;
  const listboxId = `${id}-listbox`;
  const activeDescendantId = `${id}-active`;

  const handleSelect = (selected) => {
    onChange(selected);
    onSelect(selected);
    if (closeOnSelect) {
      setIsExpanded(false);
    }
  };

  const options = React.Children.toArray(children)
    .filter((option) => option)
    .map((option, index) => {
      const isHighlighted = index === activeDescendant;
      return React.cloneElement(option, {
        onSelect: handleSelect,
        setActive: () => setActiveDescendant(index),
        id: isHighlighted ? activeDescendantId : undefined,
        isHighlighted,
      });
    });

  const previousOptions = usePrevious(options);
  // Updates to previousHighlight shouldn't trigger effects, so we use a ref.
  const previousHighlight = useRef();
  previousHighlight.current = previousOptions?.[activeDescendant]?.props.value;

  // Reset highlighted option when listbox closes
  useUpdateEffect(() => {
    if (!isExpanded) {
      setActiveDescendant(firstActiveDescendant);
    }
  }, [firstActiveDescendant, isExpanded]);

  // Highlight options while user types.
  useUpdateEffect(() => {
    if (!autohighlight) {
      // If we're filtering options, the activedescendant will be out of date,
      // so reset the activedescendant when not auto-highlighting.
      setActiveDescendant(firstActiveDescendant);
    } else {
      // Attempt to maintain the old highlighted value.
      const previousHighlightIndex = optionsRef.current
        .map((option) => option.props.value)
        .indexOf(previousHighlight.current);

      if (previousHighlightIndex >= 0) {
        // Maintain the old highlighted value
        setActiveDescendant(previousHighlightIndex);
      } else {
        // Autohighlight the closest matching option while the user types.
        const values = optionsRef.current.map((option) => option.props.value);
        const optionIndex = closestMatchIndex(values, value);
        setActiveDescendant(
          optionIndex >= 0 ? optionIndex : firstActiveDescendant,
        );
      }
    }
  }, [value, autohighlight, firstActiveDescendant]);

  // Create pointer to `options` that gets removed after unmount. Not sure if
  // this prevents a memory leak or not but thought it better to be safe.
  optionsRef.current = options;
  useEffect(() => {
    return () => {
      optionsRef.current = null;
    };
  }, []);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (!isExpanded) {
          const lastActiveDescendant = autohighlight ? options.length - 1 : -1;
          setActiveDescendant(lastActiveDescendant);
        } else {
          setActiveDescendant((prev) => {
            return prev === firstActiveDescendant
              ? options.length - 1
              : prev - 1;
          });
        }
        setIsExpanded(true);
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (!isExpanded) {
          setActiveDescendant(firstActiveDescendant);
        } else {
          setActiveDescendant((prev) => {
            return prev === options.length - 1
              ? firstActiveDescendant
              : prev + 1;
          });
        }
        setIsExpanded(true);
        break;

      case 'Enter':
        if (isExpanded) {
          // Prevent form submissions while the dropdown is open.
          event.preventDefault();
        }

        if (isExpanded && options[activeDescendant]) {
          handleSelect(options[activeDescendant].props.value);
        } else {
          setIsExpanded((prev) => !prev);
        }
        break;

      case 'Escape':
        event.preventDefault();
        setIsExpanded(false);
        break;

      default:
        break;
    }
  };

  const shouldShowListbox = isExpanded && options.length > 0;
  return (
    <React.Fragment>
      <Input
        {...props}
        role="combobox"
        className={stylesheet.root}
        autoComplete="off"
        id={id}
        label={label}
        ref={inputRef}
        aria-haspopup="listbox"
        suffixText={<MenuDownIcon className={stylesheet.chevron} />}
        aria-expanded={shouldShowListbox}
        aria-activedescendant={
          activeDescendant !== -1 && shouldShowListbox
            ? activeDescendantId
            : undefined
        }
        aria-controls={shouldShowListbox ? listboxId : undefined}
        autoFocus={autoFocus}
        value={value}
        onBlur={chainHandlers(props.onBlur, () => {
          setIsExpanded(false);
        })}
        onChange={(changed) => {
          if (!isExpanded) {
            setIsExpanded(true);
          }
          onChange(changed);
        }}
        onClick={chainHandlers(props.onClick, () =>
          setIsExpanded((prev) => !prev),
        )}
        onKeyDown={chainHandlers(props.onKeyDown, handleKeyDown)}
      />
      {isExpanded && (
        <Popup
          matchTargetWidth
          // Set an offset of 3px so listbox shows below input borders
          offset={3}
          placement="bottom-start"
          targetRef={ref}
          className={stylesheet.popup}
          unsafe_key={unsafe_key}
        >
          <ul
            id={listboxId}
            aria-labelledby={labelId}
            role="listbox"
            aria-multiselectable={ariaMultiselectable}
            // preventDefault stops the main input from losing focus.
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {options}
          </ul>
        </Popup>
      )}
    </React.Fragment>
  );
});

Combobox.propTypes = {
  /**
   * If the options filter as you type, use "list". If they remain static, use
   * "none".
   */
  'aria-autocomplete': PropTypes.oneOf(['none', 'list']).isRequired,
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby': PropTypes.string,
  /**
   * Set to true for multiselects. Marks the listbox as multiselectable.
   * @ignore
   */
  'aria-multiselectable': PropTypes.oneOf([true]),
  /**
   * When set to true, the user's cursor will focus to the input and the list dropdown will be open by default.
   * Set this to true when you have a previous interaction that can connect to this default state.
   * For example, having a button that toggles when the combobox is shown, thus linking button click interaction
   * to the combobox focus and dropdown open interaction
   */
  autoFocus: PropTypes.bool,
  /**
   * When this is true, the first option will be pre-highlighted when the
   * options appear, and the closest matching option gets highlighted as the
   * user types. When false, no value is highlighted by default.
   */
  autohighlight: PropTypes.bool,
  /** Children must be of type ComboboxOption. */
  children: PropTypes.node,
  /** Optional class name */
  className: PropTypes.string,
  /** When false, the options will remain visible after a selection is made */
  closeOnSelect: PropTypes.bool,
  /** Makes the Combobox unusable and un-clickable. */
  disabled: PropTypes.bool,
  /** Will show in place of help text if defined. Applies invalid style treatment. */
  error: PropTypes.string,
  /** Text that appears below the input */
  helpText: PropTypes.node,
  /** Visually hides the label provided by the `label` prop. */
  hideLabel: PropTypes.bool,
  /** The label of the combobox. */
  label: PropTypes.node,
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText: PropTypes.node,
  /** Triggers when the input is blurred. */
  onBlur: PropTypes.func,
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: PropTypes.func.isRequired,
  /** @ignore */
  onClick: PropTypes.func,
  /** @ignore */
  onKeyDown: PropTypes.func,
  /** Triggers when an option from the listbox is selected */
  onSelect: PropTypes.func,
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly: PropTypes.bool,
  /** @ignore */
  unsafe_key: PropTypes.any,
  /** The current value of the input.  */
  value: PropTypes.string,
};

export default Combobox;

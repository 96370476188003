import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import LiveRegion from '../LiveRegion';

const mountRegion = () => {
  // Create wrapper for live regions to load
  let liveNode;
  // If the wrapper exists, set liveNode
  if (document.getElementById('live-region')) {
    liveNode = document.getElementById('live-region');
    // If it does not exist, create one
  } else {
    liveNode = document.createElement('div');
    liveNode.id = 'live-region';
    // Append liveNode to the body
    document.body.appendChild(liveNode);
  }

  // Render both polite and assertive live regions within the liveNode
  return ReactDOM.render(
    <>
      <LiveRegion id="live-region-polite" />
      <LiveRegion id="live-region-assertive" aria-live="assertive" />
    </>,
    liveNode,
  );
};
// Mount live regions before dynamic elements are called
mountRegion();

/**
 * Announce dynamic content to screen readers based on aria-live type.
 *
 * @param  {string} type - Set aria-live value. Defaults to polite.
 * @return {Function} - Announce function to set new messages.
 */

const useAnnouncer = (type = 'polite') => {
  // Expose function to set new messages
  const [message, setMessage] = useState(null);

  const announce = useCallback((newMessage) => {
    if (newMessage) {
      setMessage(newMessage);
    } else {
      if (__DEV__) {
        console.error(`Warning: Cannot find a message to announce.`);
      }
    }
  }, []);

  useEffect(() => {
    // Create wrapper for individual message appending to live region
    const node = document.createElement('div');
    ReactDOM.render(message, node);

    // Find mounted region by type
    const mountElement =
      type === 'assertive'
        ? document.getElementById('live-region-assertive')
        : document.getElementById('live-region-polite');

    // Mount message to live region
    if (mountElement) {
      mountElement.appendChild(node);
    } else {
      console.error(`Cannot find live region of ${type}.`);
    }

    // Cleanup to remove item from node when the dynamic content has been removed
    return () => {
      mountElement.removeChild(node);
    };
  }, [message, type]);
  return announce;
};

export default useAnnouncer;

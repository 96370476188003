import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import stylesheet from './Text.less';

const Text = React.forwardRef(function Text(
  {
    appearance = 'medium',
    as: Component = 'p',
    className,
    children,
    textWrap,
    ...props
  },
  forwardedRef,
) {
  return (
    <Component
      className={cx(
        stylesheet.root,
        stylesheet[appearance],
        className,
        textWrap && stylesheet[`text-${textWrap}`],
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Component>
  );
});

Text.propTypes = {
  appearance: PropTypes.oneOf([
    'heading-2',
    'heading-3',
    'heading-4',
    'large',
    'large-secondary',
    'large-bold',
    'medium',
    'medium-secondary',
    'medium-bold',
    'small',
    'small-secondary',
    'small-bold',
    'tiny',
    'tiny-secondary',
    'tiny-bold',
  ]),
  as: PropTypes.oneOf(['p', 'span']),
  children: PropTypes.node,
  textWrap: PropTypes.oneOf(['wrap', 'nowrap', 'balance', 'pretty']),
};

export default Text;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import stylesheet from './Badge.less';

const Badge = React.forwardRef(function Badge(
  { className, children, type = 'default' },
  ref,
) {
  return (
    <span
      ref={ref}
      className={cx(stylesheet.root, stylesheet[type], className)}
    >
      {children}
    </span>
  );
});

const BADGE_TYPES = ['default', 'active', 'new', 'success', 'warning', 'error'];

Badge.propTypes = {
  /** The content inside the badge */
  children: PropTypes.node.isRequired,
  /**
   * The different variants of a badge. The default state should be used for
   * incomplete processes and numbers that aren’t related to notifications. This
   * includes states such as “draft” and “unpublished.”
   */
  type: PropTypes.oneOf(BADGE_TYPES),
};

export { BADGE_TYPES };
export default Badge;
